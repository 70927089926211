export const IMAGE_BASE_64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABAQMAAADO7O3JAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=';

// Bing Map
export const MARKER_ICON_PATH = window.HONDA_GLOBAL_HEADER?.images?.bingMarker;
export const MARKER_POWERHOUSE_ICON_PATH = window.HONDA_GLOBAL_HEADER?.images?.powerhouseMarker;
export const BING_MAP_SCRIPT = 'https://www.bing.com/api/maps/mapcontrol';
export const bingMapStyles = {
  'elements': {
    'mapElement': {
      'labelVisible': false,
    },
    'road': { visible: true, labelVisible: true },
  },
  'version': '1.0',
};
// header events, using in Header.js
export const PIN_HEADER_EVENT = 'pinHeaderEvent';
export const MOUSE_WHEEL_CUSTOM_EVENT = 'mouseWheelCustom';
export const HEADER_UP = 'header-up';
export const HEADER_DOWN = 'header-down';
// class name
export const MOUSE_CLASS = 'mouse';
export const ADA_MOUSE_CLASS_NAME = 'ada-mouse';
export const ADA_KEYBOARD_CLASS_NAME = 'ada-keyboard';

// user interaction events
export const USER_INTERACTION_EVENTS = 'scroll click mousemove mousedown mousewheel touchstart touchmove keydown';

// key codes
export const TAB_KEY = 9;
export const ENTER_KEY = 13;
export const ESCAPE_KEY = 27;
export const END_KEY = 35;
export const HOME_KEY = 36;
export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const RIGHT_KEY = 39;
export const DOWN_KEY = 40;
export const EVENT_LIST = ['mousemove', 'mousedown', 'touchstart', 'keydown', 'wheel', 'DOMMouseScroll', 'mousewheel'];

//status
export const INIT_STATUS = 'init';
export const IDLE_STATUS = 'idle';
export const LOADING_STATUS = 'loading';
export const FAILED_STATUS = 'failed';
export const DONE_STATUS = 'done';

// others string
export const ZIP_STRING = 'zip';
export const RADIUS_STRING = 'radius';
export const ALL_STRING = 'all';
// search inventory

// tracking
export const CLICK_EVENT_TRIGGER = 'click-event';
export const PAGE_LOAD_TRIGGER = 'page-load';
export const CLICK_EVENT_ACTION_TYPE = 'click event';
export const BAP_EVENT_ACTION_TYPE = 'bap event';
export const RAQ_EVENT_ACTION_TYPE = 'raq event';
export const RTDI_EVENT_ACTION_TYPE = 'rtdi event';
export const CTA_ACTION_CATEGORY = 'cta';
export const UI_CONTROLS_ACTION_CATEGORY = 'ui controls';
export const FORM_SUBMIT_ACTION_CATEGORY = 'form submit';
export const BAP_STEP_ACTION_CATEGORY = 'bap step';
export const RAQ_STEP_ACTION_CATEGORY = 'raq step';
export const RTDI_STEP_ACTION_CATEGORY = 'rtdi step';
export const DEALER_ACTIONS_ACTION_CATEGORY = 'dealer actions';
export const DOWNLOAD_ACTION_CATEGORY = 'download';
export const GLOBAL_NAV_CATEGORY = 'global nav';
export const FIND_A_DEALER_EVENT_ACTION_TYPE = 'find a dealer event';
