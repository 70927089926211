import LocomotiveScroll from 'locomotive-scroll';
import DOMPurify from 'dompurify';
import { isDesktop, getPosition, scrollPageToElement, trackingControlUI, uuidv4 } from '../utils';
import { USER_INTERACTION_EVENTS } from '../constants';

export default class UserAgent {
  constructor() {
    this.classes = {
      scrollToSection: 'scroll-to-section',
      fixedSub: 'fixed-sub',
      isActive: 'is-active',
      itemAniBlack: 'item-ani-black',
      itemAniText: 'item-ani-text',
      marqueeAnimation: 'marquee-animation',
      handleEventClass: 'opacity-0 pointer-events-none',
      bottomRight: 'bottom-right',
      jsBuildYourOwn: '.js-build-your-own',
      modModelHeroBanner: '.mod-search-results, .mod-model-hero-banner, .mod-banner-home, .event-banner, .slider-racing .swiper-slide, .mod-racing-profile-banner',
      childDomVideo: '.many-video-in-screen, .js-video-gallery, .mod-racing, .item-expanding-gallery, .mod-expanding-video, .mod-campaign, .js-video-swiper, .iframe-full-section, .box-brand-card',
      btnInfo: '.btn-info',
      textDeclaimerBox: '.text-declaimer-box',
      closeDeclaimer: '.close-declaimer',
      boxDeclaimerClone: '.box-declaimer-clone',
      mdHaveMarqueeText: '.text-disclaimer-full-width, .mod-ride-like-champion',
      textDeclaimerOpen: 'text-declaimer-open',
    };

    this.attributes = {
      dataScrollY: 'data-scroll-y',
      tabIndex: 'tabindex',
    };

    this.elementClasses = {
      textControl: '.text-control',
    };

    this.dom = {
      $html: $('html'),
      $body: $('body'),
      $htmlBody: $('html, body'),
      $header: $('.header'),
      $mainContent: $('.main-content'),
      $textAnimation: $('.ani-text'),
      $scrollToSectionLink: $('[data-scroll-selector]'),
      $marqueeText: $('.marquee-text'),
      $modSpecifications: $('.mod-specifications'),
      groupContentTop: $('.js-group-content-top'),
      $modFeatures: $('.mod-features'),
      $modAppendDisclaimer: $('.mod-trim-selection, .mod-content-image, .mod-shop-accessories, .mod-racing-teams-listing, .mod-builder-preview, .mod-additional-features-white, .mod-trim-banner-hero, .mod-gallery, .mod-campaign'),
    };
    this.stopScroll = false;
    this.$scrollEvent = null;
    this.scrollFlagScrollMobile = false;
    this.flagInitSmooth = false;
    this.oldHTMLHeight = this.dom.$html.height();

    window.videoThreeCard = [];
    window.isPlayingVideoThreeCard = false;
    window.isLoadedVideoThreeCard = false;

    window.mutedVideo = false;
    window.mainPin = 0;
    window.customScrollY = 0;
    this.positionFlag = 0;
    window.isUserInteracted = false;
  }

  init() {
    this.smooth();
    this.changeHref();
    this.defineVariableWindow();
    this.animationText();
    this.cloneDeclaimer();
    this.animationTextMarquee();
    this.addEventListeners();
    this.checkSpaceBottomDisclaimer();
    this.checkClickOT();
    this.appendTextDisclaimer();
    this.checkHasDisclaimerBottom();
    this.scrollToSection({
      delay: 1000,
      firstLoad: true,
    });
    this.stopScrollWhenUnActiveTab();
    this.catchImageOnLoaded();

    // prevent auto scroll to cache element when load page
    window.history.scrollRestoration = 'manual';
    if ($(window).innerWidth() > 1199) {
      this.scrollFlagScrollMobile = true;
      this.flagInitSmooth = true;
    }
  }

  checkHasDisclaimerBottom() {
    const parentSection = 'section';
    const $declaimerText = $('.declaimer-container.bottom-right');
    if ($declaimerText.length > 0) {
      $declaimerText.each((_i, el) => {
        const $el = $(el);
        if ($el.parents(parentSection).length > 0) {
          $el.parents(parentSection).addClass('bottom-disclaimer');
        }
      });
    }
  }

  showImagePrint() {
    // load all images lazyload when print
    window.onbeforeprint = function () {
      $('.lazy').each(function () {
        const src = $(this).attr('data-src');
        if (this.tagName === 'DIV') {
          $(this).css({
            'background-image': `url(${src})`,
          });
        } else {
          $(this).attr('src', src);
        }
        $(this).removeClass('lazy').addClass('b-loaded').removeAttr('data-src');
      });

      $('img').each((_index, el) => {
        $(el).removeAttr('loading');
      });
    };
  }

  appendTextDisclaimer() {
    this.dom.$modAppendDisclaimer.each((_index, elm) => {
      const $elm = $(elm);
      const $textDisclaimer = $elm.find('.declaimer-text.absolute, .declaimer-container.absolute');

      $textDisclaimer.each((_index2, ele) => {
        const $ele = $(ele);
        if ($ele.parents('.mod-popup-static').length === 0) {
          let $textBox;
          if ($ele.next().hasClass('text-declaimer-box')) {
            $textBox = $ele.next();
          }
          const $newDiv = $('<div>', { class: 'bg-black' });
          $ele.before($newDiv);
          $newDiv.append($ele);
          $newDiv.append($textBox);
        }
      });
    });
  }

  addEventListeners() {
    $('.c-scrollbar').click(this.scrollBarClickEvent.bind(this));
    $('.c-scrollbar_thumb').click((event) => {
      event.stopPropagation();
    });

    this.dom.$html.on('click', this.classes.btnInfo, (e) => {
      this.openDeclaimer(e);
    });

    this.dom.$html.on('click', this.classes.closeDeclaimer, (e) => {
      this.closeDeclaimer(e);
    });

    $('.btn-chevron-down').on('click', (event) => {
      this.toggleButtonActiveClass(event);
    });

    this.dom.$scrollToSectionLink.on('click', (event) => {
      this.scrollToSectionClick(event);
    });
    $(window).on('resize', () => {
      this.setTimeAnimationTextMarquee();
      this.destroyScrollResize();
      if (isDesktop()) {
        window.scrollTo(0, 0);
        this.update();
      }
      if (!this.scrollFlagScrollMobile && $(window).innerWidth() > 1199) {
        this.scrollFlagScrollMobile = true;
        window.location.reload();
      }
    });

    $(window).on('scroll', () => {
      if (!isDesktop()) {
        window.customScrollY = $(window).scrollTop();
      }
    });

    // event keydown
    $(document).on('keydown', (e) => {
      if (e.key === 'Escape') {
        this.dom.$html.removeClass(this.classes.textDeclaimerOpen);
        this.dom.$html.find(this.classes.textDeclaimerBox).addClass(this.classes.handleEventClass);
        this.dom.$html.find(this.classes.closeDeclaimer).attr(this.attributes.tabIndex, '-1');
      }
    });

    // check if user interacted, set flag to true to prepare for other & scroll to top on desktop
    $(window).on(USER_INTERACTION_EVENTS, () => {
      window.isUserInteracted = true;
      // check if user interacted on admin page, return
      if ($('.html-admin-page').length) {
        return;
      }
      if (isDesktop() && this.dom.$html.hasClass('has-scroll-smooth')) {
        $(window).scrollTop(0);
      };
    });

    // observe html tag, update scroll position on desktop when user interacted & html height change
    const observer = new MutationObserver(() => {
      if (isDesktop() && window.isUserInteracted) {
        const currentHTMLHeight = this.dom.$html.height();
        if (currentHTMLHeight !== this.oldHTMLHeight) {
          clearTimeout(this.htmlWatcher);
          this.htmlWatcher = setTimeout(() => {
            this.update();
            this.oldHTMLHeight = currentHTMLHeight;
          }, 200);
        }
      }
    });
    observer.observe(document.querySelector('html'), {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // Event click button view specs
    $('.view-spec-section').on('click', (e) => this.eventScrollTopSpecificationSection(e));
    // check click a with anchor link
    $('a').on('click', (e) => {
      const href = $(e.currentTarget).attr('href');
      const currentDomain = window.location.origin;
      const currentPathname = window.location.pathname;
      const link = new URL(href, currentDomain);
      const elementIsNotFeatureTab = $(e.currentTarget).hasClass('js-tab-title');
      if (link.origin === currentDomain && (link.pathname === currentPathname || href.charAt(0) === '#') && !elementIsNotFeatureTab) {
        this.eventScrollTopSpecificationSection(e);
      }
    });

    $('.btn-control-marquee').on('click', (event) => {
      this.eventClickButtonControlMarquee(event);
    });
  }

  checkClickOT() {
    $('.ot-sdk-show-settings').on('click', () => {
      window.scrollAgent.$scrollEvent.stop();
    });
    $(document).on('click', '.ot-close-icon, .onetrust-close-btn-handler', () => {
      window.scrollAgent.$scrollEvent.start();
    });
  }

  destroyScrollResize() {
    if ($(window).innerWidth() < 1200 && this.flagInitSmooth) {
      $('.loco-scroll-section').css('transform', '');
    }
  }

  cloneDeclaimer() {
    $(this.classes.closeDeclaimer).attr('tabindex', '-1');
    $(this.classes.textDeclaimerBox).each((_i, el) => {
      const $el = $(el);
      $el.attr('id', uuidv4());
      $el.parent().find(this.classes.btnInfo).attr('aria-labelledby', $el.attr('id'));
      if ($el.parents(this.classes.modModelHeroBanner).length > 0) {
        const $parents = $el.parents(this.classes.modModelHeroBanner);
        $el.addClass(`box-declaimer-clone box-declaimer-clone-${_i} z-100 top-20`).removeClass('z-40');
        const $clone = $el.clone();
        $el.parent().find(this.classes.btnInfo).addClass(`btn-info-clone-${_i}`).attr('data-declaimer', `box-declaimer-clone-${_i}`);
        if ($parents.find('.container-control-video').length > 0 && ($el.parent().find(this.classes.btnInfo).hasClass(this.classes.bottomRight) || $el.parent().find('.declaimer-container').hasClass(this.classes.bottomRight))) {
          const $buttonInfoSpecial = $el.parent().find(this.classes.btnInfo).removeClass('absolute my-7 mx-8');
          const $cloneBtn = $buttonInfoSpecial.clone();
          const $controlVideo = $parents.find('.control-video');
          $cloneBtn.prependTo($controlVideo);
          $buttonInfoSpecial.remove();
        }

        $clone.appendTo(this.dom.$body);
        $el.remove();
      }
      this.childDomVideoTextDeclaimer($el);
      // eslint-disable-next-line max-len
      if ($el.parents(this.classes.mdHaveMarqueeText).length > 0 && ($el.parent().find(this.classes.btnInfo).hasClass(this.classes.bottomRight) || $el.parent().find(this.classes.btnInfo).parent().hasClass(this.classes.bottomRight))) {
        const $parents = $el.parents(this.classes.mdHaveMarqueeText);
        const $buttonInfoSpecial = $parents.find(this.classes.btnInfo).removeClass('absolute my-7').addClass('btn-clone-full-width');
        const $parentsMod = $parents.parents('.mod-full-width-long-image-social-grid, .mod-ride-like-champion, .mod-full-width-long-image');
        if ($el.parents('.text-disclaimer-full-width').length > 0) {
          $buttonInfoSpecial.prependTo($parentsMod.find('.control-marquee'));
        } else {
          $buttonInfoSpecial.prependTo($parents.find('.control-marquee'));
        }
      }
    });
  }

  childDomVideoTextDeclaimer($el) {
    if ($el.parents(this.classes.childDomVideo).length > 0) {
      const $parents = $el.parents(this.classes.childDomVideo);
      if ($parents.find('.container-control-video').length > 0 && ($el.parent().find(this.classes.btnInfo).hasClass(this.classes.bottomRight) || $el.parent().find(this.classes.btnInfo).parent().hasClass(this.classes.bottomRight))) {
        const $buttonInfoSpecial = $el.parent().find(this.classes.btnInfo).removeClass('absolute my-7 mx-8');
        const $cloneBtn = $buttonInfoSpecial.clone();
        const $controlVideo = $parents.find('.control-video');
        $cloneBtn.prependTo($controlVideo);
        $buttonInfoSpecial.remove();
      }
    }
  }

  checkSpaceBottomDisclaimer() {
    const disclaimerText = $('.declaimer-text.bottom-right, .declaimer-container.bottom-right');
    disclaimerText.each((_i, el) => {
      const $el = $(el);
      if ($el.parents('.many-video-in-screen, .js-background-video, .mod-ride-like-champion, .expanding-gallery-video').length > 0) {
        $el.addClass('mb-20');
      }
    });
  };

  closeDeclaimer(e) {
    const $elementCurrentTarget = $(e.currentTarget);
    $elementCurrentTarget.attr('tabindex', '-1');
    $elementCurrentTarget.closest(this.classes.textDeclaimerBox).addClass(this.classes.handleEventClass);
    $('header').removeClass(this.classes.textDeclaimerOpen);
  }

  openDeclaimer(e) {
    const $elementCurrentTarget = $(e.currentTarget);
    if ($elementCurrentTarget.parents(this.classes.modModelHeroBanner).length > 0) {
      const $parents = $elementCurrentTarget.parents(this.classes.modModelHeroBanner);
      const $dataAttr = $elementCurrentTarget.attr('data-declaimer');
      const heightParent = $parents.height();
      $(`.${$dataAttr}`).removeClass(this.classes.handleEventClass).css('height', heightParent);
      $('header').addClass(this.classes.textDeclaimerOpen);
    } else if ($elementCurrentTarget.parent().hasClass('declaimer-container')) {
      $elementCurrentTarget.parent().next().removeClass(this.classes.handleEventClass);
    } else if ($elementCurrentTarget.parents(this.classes.childDomVideo).length > 0) {
      $elementCurrentTarget.parents(this.classes.childDomVideo).find(this.classes.textDeclaimerBox).removeClass(this.classes.handleEventClass);
    } else if ($elementCurrentTarget.hasClass('btn-clone-full-width')) {
      $(this.classes.mdHaveMarqueeText).find(this.classes.textDeclaimerBox).removeClass(this.classes.handleEventClass);
    } else {
      $elementCurrentTarget.parent().find(this.classes.textDeclaimerBox).removeClass(this.classes.handleEventClass);
    }
    $elementCurrentTarget.parent().find(this.classes.closeDeclaimer).attr('tabindex', '');
  }

  checkToScrollToElement() {
    const elementScroll = sessionStorage.getItem('elementScroll');
    if (elementScroll) {
      sessionStorage.removeItem('elementScroll');
      const $elementScrollTo = $(elementScroll);
      if ($elementScrollTo.length) {
        this.eventScrollTopSection($elementScrollTo);
      }
    }
  }

  scrollToSectionClick(event) {
    const $elementCurrentTarget = $(event.currentTarget);
    const element = $elementCurrentTarget.data('scroll-selector');
    if (element === '#') {
      return;
    }
    const $elementScrollTo = $(element);
    if ($elementScrollTo.length) {
      if ($elementCurrentTarget.is('a')) {
        sessionStorage.setItem('elementScroll', element);
      } else {
        this.eventScrollTopSection($elementScrollTo);
      }
    }
  }

  eventScrollTopSection($section) {
    if (!$section.length) {
      return;
    }
    const heightHeader = this.dom.$header.height();
    let offsetNavigation = heightHeader;
    if ($('.mod-sub-navigation').length > 0) {
      const groupContentTopHeight = this.dom.groupContentTop.height();
      offsetNavigation = groupContentTopHeight + heightHeader;
    }
    const scroll360 = (window.scrollWidthPin ? window.scrollWidthPin : 0);
    if (isDesktop()) {
      const scrollWidth = getPosition($section.get(0)).y - 10 + scroll360 - offsetNavigation;
      this.$scrollEvent.scrollTo(scrollWidth);
      setTimeout(() => {
        if (window.smoothScrollY < scrollWidth) {
          this.$scrollEvent.scrollTo(scrollWidth);
        }
      }, 900);
    } else {
      this.dom.$htmlBody.stop().animate({
        scrollTop: $section.offset().top - offsetNavigation - 10 + scroll360,
      }, 1000);
    }
  }

  setTimeAnimationTextMarquee() {
    this.dom.$marqueeText.each((_i, el) => {
      $(el).find(`.${this.classes.marqueeAnimation}`).each((i, e) => {
        const widthItem = $(e).width();
        const time = widthItem / (window.innerWidth > 767 ? 90 : 75);
        $(e).css({
          'animation-delay': `${i * time / 2}s`,
          'animation-duration': `${time}s`,
        });
      });
    });
  }

  animationTextMarquee() {
    this.dom.$marqueeText.each((_i, e) => {
      const $marqueeAnimation = $(e).find(`.${this.classes.marqueeAnimation}`);
      const cloneItem = DOMPurify.sanitize($marqueeAnimation.html());
      $marqueeAnimation.append(cloneItem).append(cloneItem).append(cloneItem);
      $(e).append(DOMPurify.sanitize($marqueeAnimation.clone().get(0)));
    });
    this.setTimeAnimationTextMarquee();
    this.dom.$marqueeText.addClass('start-marquee-text');
  }

  animationText() {
    document.querySelectorAll('.ani-text').forEach((el) => {
      let delays = 0;
      let transformItem = 0;
      let durationAnimation = 0;
      let spaceWork = 0;
      el.querySelectorAll(`.${this.classes.itemAniBlack}, .${this.classes.itemAniText}`).forEach((ele) => {
        transformItem += ele.clientWidth;
      });

      if (transformItem > 2000) {
        spaceWork = 800;
      }

      if (transformItem > 7000) {
        spaceWork = 1500;
      }

      durationAnimation = transformItem / 4 * ($(window).innerWidth() > 1199 ? 15 : 20);
      if (durationAnimation < 25000) {
        durationAnimation = 25000;
      }
      el.querySelectorAll(`.${this.classes.itemAniBlack}, .${this.classes.itemAniText}`).forEach((ele) => {
        if (!ele.classList.contains('animation-ltr')) {
          ele.animate([
            { transform: `translate(${transformItem - (transformItem / 3) + spaceWork}px)` },
            { transform: 'translate(-100%)' },
          ], {
            // sync options
            delay: delays,
            duration: durationAnimation,
            iterations: Infinity,
          });
          delays += (durationAnimation / 4);
        } else {
          ele.animate([
            { transform: 'translate(-100%)' },
            { transform: `translate(${transformItem - (transformItem / 3) + spaceWork}px)` },
          ], {
            // sync options
            delay: delays,
            duration: durationAnimation,
            iterations: Infinity,
          });
          delays += durationAnimation / 4;
        }
      });
    });
  }

  changeHref() {
    const $links = this.dom.$html.find('a');
    $links.each((_i, el) => {
      const $el = $(el);
      if ($el.attr('href') === '#') {
        $el.attr('href', '#!');
      }
    });
  }

  scrollToSection(options) {
    const { hash, firstLoad, duration, delay } = options;

    const locationHash = hash || window.location.hash;
    if (!locationHash || locationHash === '#!') {
      return false;
    }
    const safeHash = locationHash.replace(/[^a-zA-Z0-9-_]/g, '');
    const destinationElement = $(`#${DOMPurify.sanitize(safeHash)}`);
    // if the element is mod-specifications, and there is a mod-trim-banner-hero, adjust the space to scroll
    if (!destinationElement.parents('.mod-product-segment').length) {
      scrollPageToElement(destinationElement.get(0), { delay, duration, resetToTop: firstLoad, adjustSpace: 16 });
    }
    return true;
  }

  onScrollFunction(args, $locomotiveScroll) {
    const scrollY = parseFloat(args.scroll.y.toFixed(2));
    if (this.positionFlag !== scrollY) {
      this.positionFlag = scrollY;
      if ($(window).scrollTop() !== 0 && this.dom.$html.hasClass('has-scroll-smooth') && isDesktop() && !$('.html-admin-page').length) {
        $(window).scrollTop(0);
      }
      const $newLocomotiveScrollHeight = $locomotiveScroll.innerHeight();
      window.smoothScrollY = scrollY;
      if (isDesktop()) {
        window.customScrollY = scrollY - window.mainPin;
      }
      window.direction = args.direction;

      if (this.$locomotiveScrollHeight !== $newLocomotiveScrollHeight) {
        this.$locomotiveScrollHeight = $newLocomotiveScrollHeight;
        this.$scrollEvent.update();
      }

      if (this.dom.$body.hasClass('scroll-360')) {
        if (scrollY > 16) {
          this.dom.$body.addClass(this.classes.fixedSub);
        } else {
          this.dom.$body.removeClass(this.classes.fixedSub);
        }
      }
      if ($(this.classes.boxDeclaimerClone).length && (this.positionFlag !== this.scrollTopWindow)) {
        $(this.classes.boxDeclaimerClone).css('transform', `translateY(-${scrollY}px)`);
      }
    }
  }

  smooth() {
    const $locomotiveScroll = $('.locomotive-scroll');
    const isDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let multiplier = isDevice ? 1.5 : 1;
    let lerp = 0.1;
    if (isDevice) {
      lerp = 0.04;
    }
    // decrease lerp, multiplier for Edge browser to avoid fast scrolling
    if (this.dom.$modFeatures.length) {
      lerp = 0.15;
      multiplier = 0.5;
    }
    this.dom.$body.attr(this.attributes.dataScrollY, 0);
    this.$locomotiveScrollHeight = $locomotiveScroll.innerHeight();
    const scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      scrollFromAnywhere: true,
      getSpeed: true,
      getDirection: true,
      // reloadOnContextChange: true,
      smartphone: {
        smooth: !!$('.mod-rebel-features').length,
      },
      tablet: {
        breakpoint: 1200,
        smooth: true,
      },
      multiplier,
      lerp,
    });
    this.$scrollEvent = scroll;

    scroll.update();
    window.scroll = scroll;
    scroll.on('scroll', (args) => {
      this.onScrollFunction(args, $locomotiveScroll);
    });
  }

  scrollBarClickEvent(event) {
    const parentOffset = $(event.target).offset();
    const $childElement = $(event.target).find('.c-scrollbar_thumb');
    const scrollTo = $(window).innerHeight() / 2;
    const childOffset = $childElement.offset();
    const clickPosition = {
      x: event.pageX - parentOffset.left,
      y: event.pageY - parentOffset.top,
    };
    const isAboveChild = clickPosition.y < childOffset.top - parentOffset.top;
    const isBelowChild = clickPosition.y > childOffset.top - parentOffset.top + $childElement.height();
    if (isAboveChild) {
      window.scroll.scrollTo(window.scroll.scroll.instance.scroll.y - scrollTo);
    }
    if (isBelowChild) {
      window.scroll.scrollTo(window.scroll.scroll.instance.scroll.y + scrollTo);
    }
  }

  start() {
    if (this.$scrollEvent) {
      this.stopScroll = false;
      this.$scrollEvent.start();
    }
  }

  stop() {
    if (this.$scrollEvent) {
      this.stopScroll = true;
      this.$scrollEvent.stop();
    }
  }

  scrollTo($destination, opts = {}) {
    if (!this.$scrollEvent) {
      this.$scrollEvent.scrollTo($destination, opts);
    }
  }

  update() {
    if (this.$scrollEvent) {
      this.$scrollEvent.update();
    }
  }

  scrollEvent(callback) {
    if (this.$scrollEvent) {
      this.$scrollEvent.on('scroll', (args) => {
        callback(args);
      });
    }
  }

  defineVariableWindow() {
    window.isActiveItem1 = true;
    window.itemActive = 1;
    window.isCallLazy = true;
  }

  toggleButtonActiveClass(event) {
    const $elementCurrentTarget = $(event.currentTarget);
    $elementCurrentTarget.toggleClass(this.classes.isActive);
  }

  eventScrollTopSpecificationSection(event, duration = 700) {
    event.preventDefault();
    const tagHref = $(event.currentTarget).attr('href');
    const link = new URL(tagHref, window.location.origin);
    const hashSplit = $(event.currentTarget).attr('href').split('#');
    const hash = hashSplit[hashSplit.length - 1];

    // check if the link is not the same origin
    if (link.href.indexOf(window.location.origin) === -1) {
      return;
    }

    if (hash) {
      // replace hash to url
      window.history.replaceState(null, null, `#${hash}`);
      this.scrollToSection({
        hash: `#${hash}`,
        duration,
        delay: 0,
      });
    }
  }

  eventClickButtonControlMarquee(event) {
    const $elementCurrentTarget = $(event.currentTarget);
    $elementCurrentTarget.closest('section').find('.marquee-animation').toggleClass('animation-paused-state');
    $elementCurrentTarget.toggleClass('pause');

    // Toggle button text between "Play" and "Pause"
    const textControlText = $elementCurrentTarget.find(this.elementClasses.textControl).text().trim();
    const buttonText = textControlText === window.videoPlayText ? window.videoPauseText : window.videoPlayText;
    $elementCurrentTarget.find(this.elementClasses.textControl).text(buttonText);

    // Tracking event
    if ($elementCurrentTarget.hasClass('pause')) {
      trackingControlUI($elementCurrentTarget, 'pause');
    } else {
      trackingControlUI($elementCurrentTarget, 'play');
    }
  }

  // stop scroll when unActive browser tab
  stopScrollWhenUnActiveTab() {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.stop();
      } else {
        this.start();
      }
    });
  }

  catchImageOnLoaded() {
    $('img').each((_, img) => {
      img.onload = function () {
        document.dispatchEvent(new Event('anyImgLoaded'));
      };
    });
  }
}

const userAgent = new UserAgent();
if (!window.scrollAgent) {
  window.scrollAgent = userAgent;
}
userAgent.init();
